<template>
    <div>
        <p class="maintain">维护中</p>
    </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
     .maintain{
        color:red;
        font-size: 28px;
        margin-top: 180px;
    }
</style>
